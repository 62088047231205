<template>
    <simple-page-layout padded>
        <DsFilledButton
            block
            gray
            @click="$router.push({ name: 'new.hackathon' })"
        >
            <DsStatus emphasis="high">
                Admin
            </DsStatus>

            Create hackathon
        </DsFilledButton>
        <DsFilledButton

            block
            gray
            @click="$router.push({ name: 'votes' })"
        >
            <DsStatus emphasis="high">
                Admin
            </DsStatus>

            Voting results
        </DsFilledButton>

        <DsFilledButton
            block
            gray
            @click="$router.push({ name: 'guests' })"
        >
            <DsStatus emphasis="high">
                Admin
            </DsStatus>

            Guest list
        </DsFilledButton>

        <DsFilledButton
            block
            gray
            @click="$router.push({ name: 'demo' })"
        >
            <DsStatus emphasis="high">
                Admin
            </DsStatus>

            Demo
        </DsFilledButton>

        <DsFilledButton
            block
            gray
            @click="$router.push({ name: 'users' })"
        >
            <DsStatus emphasis="high">
                Admin
            </DsStatus>

            Users
        </DsFilledButton>

        <DsFilledButton
            block
            gray
            @click="$router.push({ name: 'tags' })"
        >
            <DsStatus emphasis="high">
                Admin
            </DsStatus>

            Tags
        </DsFilledButton>
    </simple-page-layout>
</template>

<script>
import SimplePageLayout from '@/components/SimplePageLayout';

export default {
    components: {
        SimplePageLayout,
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
</style>
