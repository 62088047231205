<template>
    <simple-page-layout padded>
        <div>
            <div v-if="!loading">
                <div v-for="(award, i) in hackathon.awards" :key="i">
                    <div class="title-row">
                        <h2>
                            {{ award.name }} ({{ totalVotesByAward(i) }} total votes)
                        </h2>

                        <div class="button-row">
                            <ds-spinner v-if="refreshing" class="refresh-button" />
                            <DsFilledButton v-if="isAdmin" class="reveal-button" @click.native="showNames = !showNames">
                                {{ showNames ? 'Hide!' : 'Reveal!' }}
                            </DsFilledButton>
                        </div>
                    </div>

                    <div class="chart">
                        <div
                            v-for="(project, projectIndex) in projectsByAward(i)"
                            :key="project.id"
                            class="bar"
                            :style="{ width: getWidth(project.totalVotes, i) }"
                        >
                            <div class="bar-text">
                                <h4 v-if="showNames && projectIndex < 3">
                                    {{ project.name }}
                                </h4>
                                <strong>{{ project.totalVotes }} votes</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ds-spinner v-else class="loading-spinner" />
        </div>
    </simple-page-layout>
</template>

<script>
import SimplePageLayout from '@/components/SimplePageLayout';
import { mapState, mapGetters } from 'vuex';

export default {
    components: {
        SimplePageLayout,
    },

    data() {
        return {
            loading: true,
            keyMap: {},
            showNames: false,
            interval: null,
            refreshing: false,
        };
    },

    created() {
        this.startInterval();
    },

    computed: {
        hackathon() {
            return this.getHackathon(this.$route.params.id);
        },

        sortedAwards() {
            const awards = [];

            this.hackathon.awards.forEach((award, i) => {
                const projectsByAward = (awardIndex) => {
                    const votes = this.getVotesByHackathonAward(this.hackathon.id, awardIndex);
                    const projects = {};

                    votes.forEach(({ projectId, userId }) => {
                        if (projects[projectId]) {
                            projects[projectId].push(userId);
                        } else {
                            projects[projectId] = [userId];
                        }
                    });

                    const p = Object.keys(projects).map((key) => {
                        const project = this.getProject(key);

                        const voters = projects[key]
                            .map((userId) => ({
                                ...this.getUser(userId),
                                isInProject: project?.members?.includes(userId) || project.teamLead === userId,
                            }))
                            .sort((a, b) => {
                                if (a.isInProject > b.isInProject) return -1;
                                if (a.isInProject < b.isInProject) return 1;

                                return 0;
                            });

                        return {
                            id: key,
                            totalVotes: voters.length,
                            filteredVotes: voters.filter(({ isInProject }) => !isInProject).length,
                            userList: voters,
                            name: project.name,
                        };
                    });

                    return p.sort((a, b) => {
                        if (a.totalVotes > b.totalVotes) return -1;
                        if (a.totalVotes < b.totalVotes) return 1;

                        return 0;
                    });
                };

                awards.push({ ...award, projects: projectsByAward(i) });
            });

            return awards;
        },

        ...mapState({
            isAdmin: ({ auth }) => auth.user.isAdmin,
            votes: ({ votes }) => votes.votes,
        }),

        ...mapGetters({
            getProject: 'projects/getProject',
            getUser: 'users/getUser',
            getHackathon: 'hackathons/getHackathon',
            getVotesByHackathonAward: 'votes/getVotesByHackathonAward',
        }),
    },

    methods: {
        projectsByAward(awardIndex) {
            return this.sortedAwards[awardIndex].projects;
        },

        getWidth(votes, awardIndex) {
            const leader = this.projectsByAward(awardIndex)[0]?.totalVotes;
            const width = votes / leader * 100;

            return `${width}%`;
        },

        totalVotesByAward(awardIndex) {
            const projects = this.projectsByAward(awardIndex);

            return projects && projects.length
                ? projects.reduce((acc, obj) => acc + obj.totalVotes, 0)
                : 0;
        },

        startInterval() {
            this.interval = setInterval(this.load, 5000);
        },

        async load() {
            this.refreshing = true;
            await this.$store.dispatch('votes/LOAD_VOTES');
            this.loading = false;
            this.refreshing = false;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .title-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .refresh-spinner {
        --spinner-size: #{px-to-rem(16)};
    }

    .reveal-button {
        margin-left: $spacing-200;
    }

    h2 {
        margin-bottom: $spacing-200;
    }
   .chart {
        margin-bottom: $spacing-400;

        .bar {
            padding: $spacing-100;
            font-size: $font-size-lg;
            color: $color-paper;
            display: flex;
            align-items: center;
            justify-content: center;
            height: px-to-rem(100);
            background-color: $color-green;
            margin-bottom: $spacing-200;
        }
    }
</style>
