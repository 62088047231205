<template>
    <simple-page-layout padded>
        <DsInlineAlert type="subtle">
            <h5>Choose a hackathon to see votes..</h5>
        </DsInlineAlert>

        <section class="card-layout">
            <div v-for="(hack, i) in awardHackathons" :key="hack.id" class="card-container">
                <hackathon-card
                    :link="`/admin/votes/${hack.id}`"
                    :hackathon="hack"
                    :filler-image-index="i%4"
                    link-text="View hackathon"
                />
            </div>
        </section>
    </simple-page-layout>
</template>

<script>
import { mapState } from 'vuex';

import HackathonCard from '@/components/HackathonCard';
import SimplePageLayout from '@/components/SimplePageLayout';

export default {
    components: {
        HackathonCard,
        SimplePageLayout,
    },

    data() {
        return {
            loading: true,
        };
    },

    created() {
        this.load();
    },

    computed: {
        awardHackathons() {
            return this.hackathons.filter(({ awards }) => awards && awards.length);
        },

        ...mapState({
            hackathons: ({ hackathons }) => hackathons.hackathons,
        }),
    },

    methods: {
        async load() {
            this.loading = true;
            await this.$store.dispatch('hackathons/LOAD_HACKATHONS');
            this.loading = false;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    h3 {
        margin: $spacing-200 0;
    }

    .card-layout {
        @include cardLayout(20%, 33.33%);
    }
</style>
