<template>
    <simple-page-layout padded>
        <div v-if="loading" class="loading-spinner">
            <ds-spinner />
        </div>

        <portal to="title">
            <DsIconButton gray name="arrow-left" @click="$router.push({ name: 'tags' })" />

            {{ title }}
        </portal>

        <form @submit.prevent="saveTag">
            <ds-input-field
                v-model.trim="tag.name"
                required
                label="Tag name"
            />

            <footer>
                <DsFilledButton type="submit">
                    Save
                </DsFilledButton>

                <DsTextButton
                    v-if="editing"
                    destructive
                    @click.prevent="deleteTag"
                >
                    Delete
                </DsTextButton>
            </footer>
        </form>
    </simple-page-layout>
</template>

<script>
import SimplePageLayout from '@/components/SimplePageLayout';
import { mapState } from 'vuex';

export default {
    components: {
        SimplePageLayout,
    },

    data() {
        return {
            tag: {
                name: '',
            },
            saving: false,
            loading: true,
        };
    },

    mounted() {
        this.loadTags();
    },

    computed: {
        ...mapState({
            tags: ({ tags }) => tags.tags,
        }),

        title() {
            return this.editing ? 'Edit tag' : 'Add tag';
        },

        tagId() {
            return this.$route.params?.id;
        },

        editing() {
            return this.$route?.name === 'tags.edit';
        },
    },

    methods: {
        async loadTags() {
            await this.$store.dispatch('tags/LOAD_TAGS').catch((e) => {
                this.$error({ message: `Error: ${e}`, bottom: true });
            });

            if (this.editing) {
                this.tag = this.tags.find(({ id }) => this.tagId === id);
            }

            this.loading = false;
        },

        deleteTag() {
            this.$store.dispatch('tags/REMOVE_TAG', this.tag.id);
        },

        async saveTag() {
            this.saving = true;

            const action = this.editing ? 'tags/EDIT_TAG' : 'tags/ADD_TAG';

            await this.$store.dispatch(action, this.tag)
                .then(() => {
                    this.$router.push({ name: 'tags' });
                })
                .catch((e) => {
                    this.$error({ message: `Error: ${e}` });
                });

            this.saving = false;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    footer {
        display: flex;
        justify-content: space-between;
    }
</style>
